
    import { Component, Vue } from "vue-property-decorator";
    import PasswordConfirmationModal from "@/app/authentication/components/PasswordConfirmationModal.vue";
    import RoutesEnum from "@/router/routesEnum";
    import DatePicker from "@/app/common/components/DatePicker.vue";
    import AccessToken from "../../../models/accessToken";
    import moment from "moment";
    import { AxiosError } from "axios";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import { UserCustomerModel, UserPaxModel, UserUpdateCustomerBody, UserUpdateEmailBody, UserUpdatePasswordBody, UserUpdatePaxBody } from "@/apiManager/user/userData";
    import { LoginSignInModel, LoginTypeEnum } from "@/apiManager/login/loginData";
    import { CallbackPasswordConfirmationModalEnum, ErrorServerCodeEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";

    @Component
    export default class Profile extends Vue
    {
        private loginType: LoginTypeEnum = LoginTypeEnum.None;
        private userInfoIsLoading: boolean = true;
        private userInfo: UserPaxModel = new UserPaxModel();
        private customerInfo: UserCustomerModel = new UserCustomerModel();
        private newPassword: string = "";
        private confirmNewPassword: string = "";
        private accountEmail: string = "";

        private isPasswordConfirmationModalDisplayed: boolean = false;
        private callbackConfirmationModal: CallbackPasswordConfirmationModalEnum = CallbackPasswordConfirmationModalEnum.UpdateProfilePax;

        private errorFromApiUser: boolean = false;
        private errorFromApiUserMessage: string = "";

        private errorFromApiEmail: boolean = false;
        private errorFromApiEmailMessage: string = "";

        private errorFromApiPassword: boolean = false;
        private errorFromApiPasswordMessage: string = "";

        private updateProfileInProgress: boolean = false;
        private updateEmailInProgress: boolean = false;
        private updatePasswordInProgress: boolean = false;

        get getCallbackPasswordConfirmationModalEnum(): typeof CallbackPasswordConfirmationModalEnum
        {
            return CallbackPasswordConfirmationModalEnum;
        }

        get components()
        {
            let components = {
                "password-confirmation-modal": PasswordConfirmationModal,
                "date-picker": DatePicker
            };

            return components;
        }

        get g_IsPax(): boolean
        {
            return this.loginType === LoginTypeEnum.Pax;
        }

        get g_IsCorporateAccount(): boolean
        {
            return this.loginType === LoginTypeEnum.CorporateAccount;
        }

        async mounted()
        {
            this.userInfoIsLoading = true;
            //TODO: show loading data

            try
            {
                this.loginType = AccessToken.GetType();

                if (this.loginType === LoginTypeEnum.Pax)
                {
                    this.userInfo = await this.$apiManager.user.getPaxAsync();
                }
                else if (this.loginType === LoginTypeEnum.CorporateAccount)
                {
                    this.customerInfo = await this.$apiManager.user.getCustomerAsync();
                }
                else
                {
                    this.$router.replace({name: RoutesEnum.Package});
                }
                this.accountEmail = AccessToken.GetEmail();
            }
            catch(ex)
            {
                this.$router.replace({name: RoutesEnum.Package});
            }
            finally
            {
                this.userInfoIsLoading = false;
            }
        }

        get g_ListGender(): GenderData[]
        {
            let list: GenderData[] = [];
            list.push(new GenderData("", ""));
            list.push(new GenderData("F", this.i18n(this.getRezToursLanguageEnum.sexF)));
            list.push(new GenderData("M", this.i18n(this.getRezToursLanguageEnum.sexM)));
            return list;
        }

        onClickUpdateProfilePax()
        {
            if (
                this.errorName === true ||
                this.errorLastName === true ||
                this.errorDate === true ||
                this.errorAllergies === true ||
                this.errorTelephone === true ||
                this.errorTelephoneWork === true ||
                this.errorExtension === true ||
                this.errorEmail === true ||
                this.errorAdress === true ||
                this.errorCity === true ||
                this.errorProvince === true ||
                this.errorPostalCode === true)
            {
                return;
            }
            this.callbackConfirmationModal = CallbackPasswordConfirmationModalEnum.UpdateProfilePax;
            this.isPasswordConfirmationModalDisplayed = true;
        }

        onClickUpdateProfileCustomer()
        {
            this.callbackConfirmationModal = CallbackPasswordConfirmationModalEnum.updateProfileCustomer;
            this.isPasswordConfirmationModalDisplayed = true;
        }

        onClickUpdatePassword()
        {
            if (this.newPassword === this.confirmNewPassword && this.newPassword.length > 0)
            {
                this.callbackConfirmationModal = CallbackPasswordConfirmationModalEnum.UpdatePassword;
                this.isPasswordConfirmationModalDisplayed = true;
            }
        }

        onClickUpdateEmail()
        {
            if (this.accountEmail.length > 0)
            {
                this.callbackConfirmationModal = CallbackPasswordConfirmationModalEnum.UpdateEmail;
                this.isPasswordConfirmationModalDisplayed = true;
            }
        }

        async updateProfilePax(passwordConfirmation: string)
        {
            this.errorFromApiUser = false;
            try
            {
                this.updateProfileInProgress = true;

                let body: UserUpdatePaxBody = new UserUpdatePaxBody(this.userInfo, passwordConfirmation);
                await this.$apiManager.user.updatePaxAsync(body);

                let payload = new ModalInformativePayload.Mutations.DisplayModalInformative("", this.i18n(RezToursLanguageEnum.profileUpdateSuccess));
                this.$store.commit(payload);
            }
            catch(ex)
            {
                switch((ex as AxiosError)?.response?.data?.codeError)
				{
                    case ErrorServerCodeEnum.UserUpdatePasswordInvalid:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorAuthPasswordInvalid);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorAuthUpdate);
                        break;
					case ErrorServerCodeEnum.UserCreatePaxAlreadyExist:
						this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorAuthPaxAlreayExist);
                        break;
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
						break;
                    default:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.errorFromApiUser = true;
            }
            finally
            {
                this.updateProfileInProgress = false;
            }
        }

        async updateProfileCustomer(passwordConfirmation: string)
        {
            this.errorFromApiUser = false;
            try
            {
                this.updateProfileInProgress = true;

                let body: UserUpdateCustomerBody = new UserUpdateCustomerBody(this.customerInfo, passwordConfirmation);
                await this.$apiManager.user.updateCustomerAsync(body);

                let payload = new ModalInformativePayload.Mutations.DisplayModalInformative("", this.i18n(RezToursLanguageEnum.profileUpdateSuccess));
                this.$store.commit(payload);
            }
            catch(ex)
            {
                switch((ex as AxiosError)?.response?.data?.codeError)
				{
                    case ErrorServerCodeEnum.UserUpdatePasswordInvalid:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorAuthPasswordInvalid);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorAuthUpdate);
                        break;
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
						break;
                    default:
                        this.errorFromApiUserMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.errorFromApiUser = true;
            }
            finally
            {
                this.updateProfileInProgress = false;
            }
        }

        async updatePassword(passwordConfirmation: string)
        {
            this.errorFromApiPassword = false;
            if (
                this.errorNewPassword === true &&
                this.errorConfirmNewPassword === true &&
                this.newPassword.trim().length === 0 &&
                this.confirmNewPassword.trim().length === 0
            )
            {
				return;
            }

            try
            {
                this.updatePasswordInProgress = true;

                let body: UserUpdatePasswordBody = new UserUpdatePasswordBody(passwordConfirmation, this.newPassword);
                await this.$apiManager.user.updatePasswordAsync(body);

                let payload = new ModalInformativePayload.Mutations.DisplayModalInformative("", this.i18n(RezToursLanguageEnum.profileUpdateSuccess));
                this.$store.commit(payload);
            }
            catch(ex)
            {
                switch((ex as AxiosError)?.response?.data?.codeError)
				{
                    case ErrorServerCodeEnum.UserUpdatePasswordInvalid:
                        this.errorFromApiPasswordMessage = this.i18n(RezToursLanguageEnum.errorAuthPasswordInvalid);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorFromApiPasswordMessage = this.i18n(RezToursLanguageEnum.errorAuthUpdate);
                        break;
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorFromApiPasswordMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
						break;
                    default:
                        this.errorFromApiPasswordMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.errorFromApiPassword = true;
            }
            finally
            {
                this.updatePasswordInProgress = false;
            }
        }

        async updateEmail(passwordConfirmation: string)
        {
            this.errorFromApiEmail = false;
            if (
                this.errorEmailAccount === true &&
                this.accountEmail.trim().length === 0
            )
            {
				return;
            }

            try
            {
                this.updateEmailInProgress = true;

                let body: UserUpdateEmailBody = new UserUpdateEmailBody(this.accountEmail, passwordConfirmation);
                let model: LoginSignInModel = await this.$apiManager.user.updateEmailAsync(body);
                AccessToken.Set(model.token);

                let payload = new ModalInformativePayload.Mutations.DisplayModalInformative("", this.i18n(RezToursLanguageEnum.profileUpdateSuccess));
                this.$store.commit(payload);
            }
            catch(ex)
            {
                switch((ex as AxiosError)?.response?.data?.codeError)
				{
                    case ErrorServerCodeEnum.UserUpdatePasswordInvalid:
                        this.errorFromApiEmailMessage = this.i18n(RezToursLanguageEnum.errorAuthPasswordInvalid);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorFromApiEmailMessage = this.i18n(RezToursLanguageEnum.errorAuthUpdate);
                        break;
                    case ErrorServerCodeEnum.UserCreateEmailAlreadyUsed:
                        this.errorFromApiEmailMessage = this.i18n(RezToursLanguageEnum.errorAuthEmailAlreayUsed);
                        break;
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorFromApiEmailMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
						break;
                    default:
                        this.errorFromApiEmailMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.errorFromApiEmail = true;
            }
            finally
            {
                this.updateEmailInProgress = false;
            }
        }

        get errorLastName()
        {
            if (this.userInfo.lastName.length > 0)
			{
                let format = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

                if (this.userInfo.lastName.match(format))
                {
                    return false;
                }
                else
                {
                    return true;
                }
            }
        }

        get errorName()
        {
            if (this.userInfo.firstName.length > 0)
			{
                let format = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

                if (this.userInfo.firstName.match(format))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorDate()
        {
            if (this.userInfo.birthDate.length > 0)
            {
                if( !moment(this.userInfo.birthDate).isValid() || moment(this.userInfo.birthDate).isAfter(moment()) || moment(this.userInfo.birthDate).isBefore("1900-01-01"))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return true;
            }
        }

        get errorTelephone()
		{
			if (this.userInfo.telephone.length > 0)
			{
				let phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
				if (this.userInfo.telephone.match(phoneno))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorTelephoneWork()
		{
			if (this.userInfo.telephoneWork.length > 0)
			{
				let phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
				if (this.userInfo.telephoneWork.match(phoneno))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorExtension()
		{
			if (this.userInfo.telephoneExt.length > 0)
			{
                if (this.userInfo.telephoneExt.length < 10)
                {
                    return false;
                }
                else
                {
                    return true;
                }
			}
        }

        get errorEmail()
		{
			if (this.userInfo.email.length > 0)
			{
                if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.userInfo.email))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorAdress()
		{
			if (this.userInfo.address.length > 0)
			{
                if (this.userInfo.address.length < 70)
                {
                    return false;
                }
                else
                {
                    return true;
                }
			}
        }

        get errorAllergies()
		{
			if (this.userInfo.allergies.length > 0)
			{
                if (this.userInfo.allergies.length < 70)
                {
                    return false;
                }
                else
                {
                    return true;
                }
			}
        }

        get errorCity()
        {
            if (this.userInfo.city.length > 0)
			{
                let format = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

                if (this.userInfo.city.match(format))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorProvince()
        {
            if (this.userInfo.province.length > 0)
			{
                let format = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

                if (this.userInfo.province.match(format))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorPostalCode()
		{
            return false;
			// if (this.userInfo.postalCode.length > 0)
			// {
            //     if (this.userInfo.postalCode.length <= 7)
            //     {
            //         return false;
            //     }
            //     else
            //     {
            //         return true;
            //     }
			// }
        }

        get errorEmailAccount()
		{
			if (this.accountEmail.length > 0)
			{
                if
                (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.accountEmail))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }
        get errorNewPassword()
		{
			if (this.newPassword.length > 0)
			{
				if (this.newPassword.length < 17)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        get errorConfirmNewPassword()
		{
			if (this.confirmNewPassword.length > 0)
			{
				if (this.confirmNewPassword === this.newPassword)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
        }

        onClickHome()
		{
			this.$router.push({ name: RoutesEnum.Package });
		}
    }

    class GenderData
    {
        value: string;
        label: string;

        constructor(p_Value: string, p_Label: string)
        {
            this.value = p_Value;
            this.label = p_Label;
        }
    }
